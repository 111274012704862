import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import AnimatedMask from "components/AnimatedMask"

import fadeAnimation from "utils/fadeAnimation"

const Application = ({ pageData }) => {
  return (
    <section className="system-wet-application">
      <div className="container-fluid">
        <motion.h3 {...fadeAnimation}>
          {pageData?.acfSystemWet?.whyImagesHeader}
        </motion.h3>
        <div className="row justify-content-center">
          {pageData?.acfSystemWet?.whyImagesGallery?.map((item, index) => (
            <div className="col-md-6" key={index}>
              <motion.div
                className="system-wet-application__thumbnail"
                {...fadeAnimation}
              >
                <AnimatedMask />
                <img src={item?.localFile?.publicURL} alt="" />
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Application
