import React, { useState } from "react"
import { graphql } from "gatsby"
import { AnimatePresence } from "framer-motion"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"
import Bestsellers from "components/Bestsellers"
import ProductPopup from "components/ProductPopup"

import { Content, Why, Application, Buy } from "page_components/system-wet"

const SystemWet = ({ data }) => {
  const [popup, setPopup] = useState(false)
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout>
      <Breadcrumbs title="System WET" />
      <PageHeader title="System WET" />
      <Content pageData={pageData} />
      <Why pageData={pageData} />
      <Application pageData={pageData} />
      <Buy setPopup={setPopup} />
      <Bestsellers />
      <BanerContact withMargin />
      <AnimatePresence mode="wait">
        {popup && <ProductPopup popup={popup} setPopup={setPopup} />}
      </AnimatePresence>
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3NDY2" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfSystemWet {
          description
          contentImage {
            localFile {
              publicURL
            }
          }
          whyHeader
          whyIcons {
            header
            description
            icon {
              localFile {
                publicURL
              }
            }
          }
          whyImagesHeader
          whyImagesGallery {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default SystemWet
