/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React, { useState, useEffect, forwardRef } from "react"
import { Link } from "gatsby"
import { NumericFormat } from "react-number-format"
import axios from "axios"
import { motion } from "framer-motion"

import FavoriteAction from "components/FavoriteAction"

import Logo from "assets/images/logo.svg"

import { CUBIC_BEZIER } from "utils/constants"

const WallpaperTile = forwardRef((props, ref) => {
  const { item, marginZero } = props

  const default_featured_image = item?.featuredImage?.node?.localFile?.publicURL
  const [featured_image, setFeaturedImage] = useState(default_featured_image)
  const [product_variations, setProductVariations] = useState(null)

  useEffect(() => {
    ;(async () => {
      await getProductVariation()
    })()
  }, [])

  const getProductVariation = async () => {
    try {
      const variations = await axios?.get(
        `https://cms.wallcraft.com.pl/wp-json/wc/v3/products/${item?.databaseId}/variations?consumer_key=ck_6308078b67bd575fd2cb12f92e564622d4fe3938&consumer_secret=cs_405c5f1c93af27c9954a41bcacc2eb33023684e3`
      )

      setProductVariations([
        ...variations?.data?.reverse()?.map(item => ({
          id: item?.id,
          name: item?.attributes[0].option,
          price: item?.price,
          image: item?.image?.src,
        })),
      ])
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Link
      ref={ref}
      to={item?.uri ? item?.uri.replace("/produkt", "") : "/"}
      className={`wallpaper-tile${
        marginZero ? " wallpaper-tile--margin-zero" : ""
      }`}
    >
      <div
        className="wallpaper-tile__image"
        style={{
          backgroundImage: `url('${featured_image}')`,
        }}
      >
        <div className="wallpaper-tile__labels">
          {item?.productCategories?.nodes
            ?.filter(node => node?.name !== "Tapety")
            .map((node, index) => (
              <span
                className={node?.name?.toLowerCase().replace("ś", "s")}
                key={index}
              >
                {node?.name}
              </span>
            ))}
        </div>
        {item?.id && <FavoriteAction id={item?.id} />}
        {!item?.featuredImage?.node?.localFile?.publicURL && (
          <img className="wallpaper-tile__logo" src={Logo} alt="" />
        )}
        {product_variations?.length > 0 && (
          <motion.div
            className="wallpaper-tile__variations"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "tween", ease: CUBIC_BEZIER, duration: 0.3 }}
          >
            {product_variations?.slice(0, 3)?.map((item, index) => (
              <button
                key={index}
                onMouseEnter={() => setFeaturedImage(item.image)}
                onMouseLeave={() => setFeaturedImage(default_featured_image)}
              >
                <div className="wallpaper-tile__variations-item">
                  <div>
                    <div
                      className="wallpaper-tile__variations-background"
                      style={{ backgroundImage: `url('${item?.image}')` }}
                    >
                      Wariation
                    </div>
                  </div>
                </div>
              </button>
            ))}
            {product_variations?.length > 3 && (
              <div className="wallpaper-tile__variations-item visible">
                +{product_variations?.length - 3}
              </div>
            )}
          </motion.div>
        )}
      </div>
      <div className="wallpaper-tile__info">
        <div>
          <h4>{item?.name}</h4>
          {item?.attributes?.nodes
            ?.filter(node => node?.name === "Kolekcja")
            .map((node, index) => (
              <span key={index} className="wallpaper-tile__info-option">
                {node?.name}: {node?.options?.map(i => i.replace("-", " "))}
              </span>
            ))}
        </div>

        <div className="wallpaper-tile__info-price">
          <strong>
            <NumericFormat
              value={item?.price?.replace(/,/g, "")}
              displayType={"text"}
              thousandSeparator={" "}
              decimalScale={0}
              suffix={" zł"}
            />
          </strong>
          {(item?.productCategories?.nodes[0]?.slug === "tapety" ||
            item?.productCategories?.nodes[0]?.slug === "bestseller" ||
            item?.productCategories?.nodes[0]?.slug === "nowosci") &&
            item?.price && (
              <span>
                / m<sup>2</sup>
              </span>
            )}
        </div>
      </div>
    </Link>
  )
})

export default WallpaperTile
